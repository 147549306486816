import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader1 = {
    title: 'NexL / Ed-tech SNS App',
    paragraph: 'NexL aims to be an all-around app college students can use for '
    +'e-commerce,powered by its edtech academic social networking functionality.'
    +'\n\n'
    +'NexL aims to help students perform better academically and achieve greater peer-to-peer connection socially. Studies have found student-to-student interaction increases academic achievement and student satisfaction. However, student-to-student interaction is difficult to achieve in large classroom settings. '
    +'\n\n'
    +'This is particularly important in the age of Covid19, where many school classes are carried out through Zoom. NexL is proposing an innovative online student-to-student e-commerce and academic social networking platform to enhance academic achievement and student satisfaction. The combination of e-commerce and social features digitally emulates the traditional schooling experience and gives NexL its unique value to users.'
    +'\n\n'
    +'Apart from the e-commerce function of the platform, when students sign up on the web or the mobile application, they can enter their course information to be grouped together.'
  };

  const sectionHeader2 = {
    title: 'B2B',
    paragraph: 'Partnering with NEXL allows you to leverage our collective experience and expertise into your software development project.'
    +'\n\n'
    +'We value skillset, talent, and data analytics at the core of our development. We hope to establish long-term business relationships and help you solve your software development challenges.'
    +'\n\n'
    +'We provide several outsourcing services options tailored to the needs of our partners.'
  };

  return (
    <section
      {...props}
      className={outerClasses} style={{whiteSpace: "pre-line"}}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader1} className="center-content" />
          <SectionHeader data={sectionHeader2} className="center-content" />
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;